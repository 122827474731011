const translationEN = {
    AndijonDavlatsUniversiteti:"Andijan state",
    univer:"university",

    MainPage :"Main Page",
    Doctorate:"Doctorate",
    Regulation:"Regulation",
    ListofRequiredDocuments:"List of required documents",
    Quota:"Quota 2024",
    SpecialtiesOfDoctorate :"Specialties of doctorate",
    Doctorates:"Doctorates",
    QualificationExams :"Qualification exams",
    ScientificCouncil :"Scientific council",
    ScientificAdvice:"Scientific advice",
    ScientificAndTechnicalCouncil:"Scientific and Technical Council",
    ScientificProjects:"Scientific projects",
    GovernmentProject:"Government project",
    InternationalProjects:"International projects",
    ObjectsOfIntellectualProperty:"objects of intellectual property",
    Patents:"Patents",
    Certificates:"Certificates",
    Journals:"Journals",
    HumanitarianResearch:"Humanitarian research",
    pedagogicalResearch:"pedagogical research",
    ChemicalResearch:"Chemical research",
    BiologicalResearch:"Biological research",
    PhysicsAndMathematics:"Physics and Mathematics",
    TalentedStudents:"Talented students",
    ScientificCircles:"Scientific circles",
    ScholarshipByTheGovernments:"Scholarship by the Government's",
    ScientificConferences:"Scientific conferences",

    CommodityCHemistryAndFolkMedicine:"Commodity chemistry and folk medicine",
    HistoryOfUzbekistan:"History of Uzbekistan",
    Management:"Management",
    UzbekLanguage:"Uzbek language",
    UzbekLiterature:"Uzbek literature",
    SocialPhilosophy:"Social philosophy",
    inBiologicalSciencesv:"in biological sciencesv",

    StateGrant:"Government project",
    ForeignGrant:"International projects",

    ScientificAndTechnicalCouncil:"Scientific and technical council",
    StatuteOfTHeScientificAndTechnicalCouncil:"Statute of the Scientific and Technical Council",
    CompositionOfTHeScientificAndTechnicalCouncil:"Composition of the Scientific and Technical Council",
    ScientificConferences:"Scientific conferences",
} 

export default translationEN;